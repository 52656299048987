/* reset css */
a:focus {
  outline: none;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: none;
}

body,
html {
  min-height: 100%;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  letter-spacing: inherit;
  font-weight: inherit;
}

fieldset,
img,
abbr,
acronym {
  border: 0 none;
}

table {
  border-collapse: separate;
  border-spacing: 0;
  border: 0 none;
}

caption,
th,
td {
  text-align: left;
  font-weight: inherit;
  border: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
  display: inline;
  zoom: 1;
}

img,
video {
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  object-fit: cover;
  image-rendering: -moz-auto;
  /*firefox*/
  image-rendering: -o-auto;
  /*opera*/
  image-rendering: -webkit-optimize-contrast;
  /*webkit*/
  image-rendering: auto;
  -ms-interpolation-mode: nearest-neighbor;
  /* -webkit-perspective: 1; */
  -webkit-tap-highlight-color: transparent;
}

ul,
ol,
dl,
li {
  list-style: none;
}

a {
  display: block;
  text-decoration: none;
  color: inherit;
}

i {
  font-style: normal;
  font-weight: normal;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input,
select,
label {
  vertical-align: middle;
}

button,
input,
select,
textarea {
  margin: 0;
  font-weight: normal;
  letter-spacing: normal;
  background: none;
}

button,
input {
  line-height: normal;
  box-shadow: none;
}

input {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button {
  cursor: pointer;
}

button,
select {
  text-transform: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: none;
}

address {
  font-style: normal;
}

html {
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -o-text-size-adjust: none;
}

input::-webkit-date-and-time-value {
  margin-top: 0;
  text-align: left;
  width: 100%;
}

/* style */
@import url("https://cdn.jsdelivr.net/gh/wanteddev/wanted-sans@v1.0.3/packages/wanted-sans/fonts/webfonts/variable/complete/WantedSansVariable.min.css");

:root {
  --black: #3c3c3c;
  --white: #ffffff;
  --gray01: #e8e8e8;
  --gray02: #a5a5a5;
  --gray03: #f1f4f7;
  --key01: #008ecf;
  --key02: #70dca1;
  --error: #e9373a;
  --brown: #cd7d30;
  --gradient: linear-gradient(63deg, #66bf93 -6.49%, #007da5 116.53%);
  --white-filter: invert(100%) sepia(0%) saturate(624%) hue-rotate(238deg)
    brightness(106%) contrast(102%);
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Wanted Sans Variable", sans-serif;
  font-size: 20px;
  font-weight: normal;
  letter-spacing: -0.02em;
  color: var(--black);
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
}

html.hidden {
  overflow: hidden;
}

body.hidden {
  height: auto;
  overflow: hidden;
}

.inner {
  width: 1300px;
  margin: 0 auto;
}

.formInner {
  width: 510px;
  margin: 0 auto;
}

.mbBr {
  display: none;
}

main {
  padding-top: 80px;
  min-height: 100vh;
}

button,
input,
textarea,
select {
  font-family: "Wanted Sans Variable", sans-serif;
  font-size: inherit;
  font-weight: inherit;
  letter-spacing: -0.02em;
  color: inherit;
}

.articleTitleWrap {
  margin-bottom: 50px;
  font-weight: 600;
}

.articleTitleSub {
  margin-bottom: 10px;
  font-size: 24px;
  color: var(--gray02);
}

.articleTitle {
  font-size: 38px;
}

@media (max-width: 1400px) {
  body {
    font-size: calc(100vw * (20 / 1400));
  }

  .inner {
    width: calc(100vw * (1300 / 1400));
  }

  .formInner {
    width: calc(100vw * (510 / 1400));
  }

  main {
    padding-top: calc(100vw * (80 / 1400));
  }

  .articleTitleWrap {
    margin-bottom: calc(100vw * (50 / 1400));
  }

  .articleTitleSub {
    margin-bottom: calc(100vw * (10 / 1400));
    font-size: calc(100vw * (24 / 1400));
  }

  .articleTitle {
    font-size: calc(100vw * (38 / 1400));
  }
}

@media (max-width: 767px) {
  body {
    font-size: calc(100vw * (18 / 375));
  }

  .inner {
    width: calc(100vw * (335 / 375));
  }

  .formInner {
    width: calc(100vw * (335 / 375));
  }

  main {
    padding-top: calc(100vw * (50 / 375));
  }

  .articleTitleWrap {
    margin-bottom: calc(100vw * (20 / 375));
  }

  .articleTitleSub {
    margin-bottom: calc(100vw * (10 / 375));
    font-size: calc(100vw * (20 / 375));
  }

  .articleTitle {
    font-size: calc(100vw * (24 / 375));
  }

  .pcBr {
    display: none;
  }

  .mbBr {
    display: block;
  }
}
